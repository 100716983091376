<template>
  <div class="moduleConfiguration-page">
    <div class="back-button">
      <div @click="backFun">
        <i class="iconfont its_ba_fanhui"></i>
      </div>
      <el-button type="primary" size="small" @click="addFun">添加</el-button>
    </div>
    <el-tabs
      v-loading="loading"
      v-model="activeName"
      type="card"
      @tab-click="handleClick"
    >
      <el-tab-pane
        v-for="(item, index) in tabList"
        :key="index"
        :label="item.label"
        :name="item.name"
      >
        <Table
          v-if="isShowTable"
          :table-data.sync="tableData"
          :title-name="titleName"
          :operation="true"
          :col-class-type="true"
          operation-width="150"
        >
          <template slot-scope="scope">
            <div>
              <template>
                <el-button
                  type="text"
                  size="small"
                  sort="primary"
                  @click="updateFun(scope.scopeRow)"
                  >编辑</el-button
                >
              </template>
            </div>
          </template>
        </Table>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
      @close="handleClose"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="模块代码：" prop="modelCode">
          <el-input
            v-model="ruleForm.modelCode"
            :disabled="isDisabled"
          ></el-input>
        </el-form-item>
        <el-form-item label="模块名称：" prop="modelName">
          <el-input v-model="ruleForm.modelName"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" size="small" @click="confirmFun"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  querySystemThemeModelListAllAPI,
  updateSystemThemeModelAPI,
  saveSystemThemeModelAPI,
} from "@/api/lib/api.js";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      modelType: null,
      isDisabled: false,
      dialogTitle: "",
      isShowTable: true,
      loading: false,
      dialogVisible: false,
      ruleForm: {
        modelCode: "",
        modelName: "",
      },
      rules: {
        modelCode: [
          { required: true, message: "请输入模块代码", trigger: "blur" },
        ],
        modelName: [
          { required: true, message: "请输入模块名称", trigger: "blur" },
        ],
      },
      activeName: "0",
      tabList: [
        { label: "首页", name: "0" },
        { label: "Tab", name: "1" },
        { label: "主金刚区", name: "2" },
        { label: "次金刚区", name: "3" },
      ],
      tableData: [],
      titleName: [
        {
          title: "模块代码",
          props: "modelCode",
        },
        {
          title: "模块名称",
          props: "modelName",
        },
      ],
    };
  },
  filters: {},
  computed: {},
  watch: {},
  created() {
    this.renderData();
  },
  mounted() {},
  methods: {
    // 表格数据渲染
    renderData() {
      this.isShowTable = false;
      this.loading = true;
      this.tableData = [];
      querySystemThemeModelListAllAPI()
        .then((res) => {
          if (res.code == "SUCCESS") {
            let activeName = Number(this.activeName) + 1;
            this.tableData = res.data.filter((item) => {
              return item.modelType === activeName;
            });
            //1-首页 2-TAB 3-金刚区 4-副金刚区
            this.modelType = activeName;
          }
          this.isShowTable = true;
          this.loading = false;
        })
        .catch(() => {
          this.isShowTable = true;
          this.loading = false;
        });
    },
    confirmFun() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          delete this.ruleForm.$index;
          delete this.ruleForm.DataLength;
          let path;
          if (this.dialogTitle === "添加") {
            path = saveSystemThemeModelAPI;
            this.ruleForm.modelType = this.modelType;
          } else {
            path = updateSystemThemeModelAPI;
          }
          path(this.ruleForm).then((res) => {
            if (res.code == "SUCCESS") {
              this.$message.success("修改成功");
              this.dialogVisible = false;
              this.renderData();
            }
          });
        }
      });
    },
    handleClose() {
      this.ruleForm = {
        modelCode: "",
        modelName: "",
      };
      this.isDisabled = false;
      this.$refs.ruleForm.resetFields();
    },
    addFun() {
      this.dialogTitle = "添加";
      this.dialogVisible = true;
    },
    updateFun(row) {
      this.dialogTitle = "编辑";
      this.isDisabled = true;
      this.dialogVisible = true;
      this.ruleForm = this.deepClone(row);
    },
    handleClick(tab, event) {
      this.renderData();
    },
    backFun() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang='scss' scoped>
.moduleConfiguration-page {
  .back-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    & > div {
      width: 32px;
      height: 32px;
      color: #333333;
      font-size: 15px;
      background-color: white;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 0px 3px 1px rgba(52, 52, 52, 0.1);
    }
  }
  .el-tabs {
    background-color: #ffffff;
    padding: 20px;
  }
  .demo-ruleForm {
    .el-input {
      width: 70%;
      height: 32px;
    }
  }
}
</style>
